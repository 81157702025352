var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = "/monitoring-tunnel";
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"26948ab8c2ca2a506687b6762defaf9d9cc5bb82"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import { env } from '@env'
import {
  init,
  BrowserTracing,
  BrowserOptions,
  addGlobalEventProcessor,
  Replay,
} from '@sentry/nextjs'
import { looseUrlSchema } from '@purposity/utils/edge'
import { deploymentUrlBase } from '@config/AppConfig'
import { PURPOSITY_ENV, SENTRY_DSN } from './constants'
import { isCheckly, ZodProcessor } from './sentry.common'

init({
  dsn: SENTRY_DSN,
  environment: PURPOSITY_ENV,

  tracesSampleRate: 1.0,

  replaysOnErrorSampleRate: 1.0,
  replaysSessionSampleRate: PURPOSITY_ENV === 'preview' ? 1 : 0.1,

  integrations: [
    new BrowserTracing({
      tracePropagationTargets: [
        /^\//,
        'localhost',
        deploymentUrlBase.host,
        'wwww.purposity.com',
        // RE_PURPOSITY_HOSTNAME,
        looseUrlSchema.catch(() => undefined).parse(env.NEXT_PUBLIC_API_ORIGIN)
          ?.host,
        looseUrlSchema.catch(() => undefined).parse(env.NEXT_PUBLIC_CDN_ORIGIN)
          ?.host,
      ],
    }),

    new Replay(),
  ],

  beforeSend(event, _hint) {
    if (isCheckly(event)) return null
    return event
  },
  beforeSendTransaction(event, hint) {
    if (isCheckly(event)) return null
    return event
  },
} satisfies BrowserOptions)

addGlobalEventProcessor(ZodProcessor)
